@import "@/styles/mixins";

:import("@/components/ui/LayoutOverlay/OverlayTitle/styles.module.scss") {
  overlay-title: overlay-title;
  overlay-title__title: overlay-title__title;
  overlay-title__subtitle: overlay-title__subtitle;
  overlay-title__button: overlay-title__button;
}

:import("@/components/ui/Buttons/styles.module.scss") {
  buttons: buttons;
}

.layout-overlay {
  flex: 0 1 100%; display: flex; flex-direction: column; flex-wrap: nowrap; box-sizing: border-box; overflow: hidden; max-width: 100vw;

  @include not-mobile {
    padding: var(--gridunit);
  }

  > * { width: 100%; box-sizing: border-box; }

  &__inner { flex: 0 1 100%; display: flex; flex-direction: column; flex-wrap: nowrap; box-sizing: border-box; overflow: hidden; }

  &__title {
    padding: var(--gridunit) var(--gridunit) 0 var(--gridunit) !important;

    .overlay-title { border-bottom: 1px solid var(--color-primary-500); position: relative; padding-bottom: var(--gridunit-small); }

    .overlay-title__button { right: 0; top: 0; }
  }

  &__content {
    position: relative; z-index: 0; background-color: var(--color-white); max-width: 100vw; display: flex; flex-direction: column; flex: 1 1 auto !important; box-sizing: border-box;
    @include overflow(false);

    > *:first-child { flex-grow: 1; }

    H4 + * { margin-top: var(--gridunit) !important; }
  }

  &__footer {
    background-color: var(--color-white); padding: var(--gridunit-small) var(--gridunit) max(var(--gridunit), #{'env(safe-area-inset-left)'}) var(--gridunit) !important; border-top: 1px solid var(--color-neutral-200);

    > * { margin-top: 0 !important; }

    .buttons {
      @include mobile {
        flex-direction: row; flex-wrap: wrap; justify-content: stretch;

        > * { flex: 1 0 auto !important; }
      }
      @include not-mobile { justify-content: flex-end; }
    }
  }

  &.size-filter & {
    &__title {
      .overlay-title {
        border: none !important;
        justify-content: stretch;

        .overlay-title__title { flex: 1 1 auto; text-align: center; position: relative; }

        &:has(.overlay-title__title) {
          .overlay-title__button {
            position: absolute; top: 2px; margin: 0;

            &:first-child { left: 0; }

            &:last-child { right: 0; }
          }
        }

        &:has(.overlay-title__button) .overlay-title__title { padding-left: calc(32px + var(--gridunit-small)); padding-right: calc(32px + var(--gridunit-small)); }
      }
    }
  }

  @include not-mobile {
    &.size-default { align-items: center; justify-content: center; flex: 1 0 auto; }

    &.size-default > * { max-width: 800px; flex: 0 0 auto; }

    &.size-default &__content { overflow: hidden; border-radius: var(--radius-large); }

    &.size-small { align-items: center; justify-content: center; flex: 0 0 auto; }

    &.size-small > * { max-width: 600px; flex: 0 0 auto; }

    &.size-small &__content { overflow: hidden; border-radius: var(--radius-large); }

    &.size-medium { align-items: center; justify-content: center; flex: 1 0 auto; }

    &.size-medium > * { max-width: 800px; flex: 0 0 auto; }

    &.size-medium &__content { overflow: hidden; border-radius: var(--radius-large); }

    &.size-wide { align-items: center; justify-content: center; flex: 1 0 auto; }

    &.size-wide > * { max-width: 1000px; flex: 0 0 auto; }

    &.size-wide &__content { overflow: hidden; border-radius: var(--radius-large); }

    &.size-max { flex: 1 0 auto; padding: 0; }

    &.size-max > * { max-width: 100%; }

    &.size-max &__inner { position: relative; }

    &.size-max &__content { position: absolute; inset: 0; }

    &.size-mobilemenu { align-items: flex-start; flex: 1 0 auto; padding: 0; }

    &.size-mobilemenu > * { width: 360px; }

    &.size-mobilemenu &__content { overflow: hidden; }

    &.size-filter { align-items: center; justify-content: center; flex: 1 0 auto; }

    &.size-filter > * { max-width: var(--content-maxwidth); flex: 0 0 auto; }

    &.size-filter &__title { padding-bottom: var(--gridunit-large) !important; border-radius: var(--radius-large) var(--radius-large) 0 0; }

    &.size-filter &__content { overflow: hidden; border-radius: var(--radius-large) var(--radius-large) 0 0; }

    &.size-filter &__inner { margin-top: calc(var(--gridunit-large) * -1); }

    &.size-filter &__footer { border-radius: 0 0 var(--radius-large) var(--radius-large); }
  }

  @include mobile {
    &.size-mobilemenu { align-items: stretch; justify-content: flex-start; flex: 1 0 auto; }

    &.size-mobilemenu > * { flex: 1 0 100%; }

    &.size-mobilemenu &__content { overflow: hidden; }

    &.size-filter &__title { padding: 0 0 var(--gridunit-large) 0 !important; }

    &.size-filter &__inner { margin-top: calc(var(--gridunit-large) * -1); border-radius: var(--radius-large) var(--radius-large) 0 0; }
  }
}
