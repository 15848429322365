@import '@/styles/mixins';

.overlay-title {
  display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; gap: var(--gridunit-small);

  &__title { font-size: 24px; line-height: 32px; font-weight: var(--medium); overflow: hidden; text-overflow: ellipsis; }

  &__subtitle { font-size: 14px; font-weight: var(--medium); margin-top: 0.45rem; overflow: hidden; text-overflow: ellipsis; }

  &__button { display: flex; width: 28px; height: 28px; align-items: center; justify-content: center; }

  @include mobile {
    margin-top: var(--gridunit-small);

    &__button { margin-left: auto; }
  }

  @include not-mobile {
    &__button { position: absolute; right: var(--gridunit-medium); top: var(--gridunit-medium); color: var(--color-link); display: flex; }
  }
}